<template>    
    <b-row>
        <b-col lg="12">            
            <b-card header="General"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>
                    <b-col lg="8">
                        <b-form-group label="Razón Social">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.business_name">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>
                    
                    <b-col lg="4">
                        <FindObject render="search"
                                    type="erpFiscalConditions" 
                                    @select-object="loadFiscalConditions($event)" 
                                    :valueID="crud.form.fiscal_conditions_id"                                        
                                    :key="componentKey"/>   
                    </b-col>

                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpTypeDocuments" 
                                    @select-object="loadTypeDocuments($event)" 
                                    :valueID="crud.form.types_documents_id"
                                    :key="componentKey"/>                       
                    </b-col>
                            
                    <b-col lg="6">
                        <b-form-group label="Número Documento">
                            <b-form-input type="text"
                                        size="md"
                                        v-model="crud.form.documents_number"
                                        @change="validDocumentNumber()">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="6">
            <b-card header="Ubicación"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">            
                <b-row>
                    <b-col lg="8">
                        <b-form-group label="Dirección">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.address">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Código Postal">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.postal_code">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Ciudad">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.city">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Provincia">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.state">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="País">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.country">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="6">
            <b-card header="Por Defecto"     
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>           
                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpPointsSales" 
                                    display="full_identification"
                                    @select-object="loadPointsSales($event)" 
                                    :valueID="crud.form.points_sales_id"
                                    description="Punto de venta por defecto"
                                    :key="componentKey"/>              
                    </b-col>

                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpMethodsPayment" 
                                    @select-object="loadMethodsPayment($event)" 
                                    :valueID="crud.form.methods_payment_id"
                                    description="Método de pago por defecto"
                                    :key="componentKey"/>                                      
                    </b-col>

                    <b-col lg="12">
                        <b-form-group label="Límite de Crédito">
                            <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.form.credit_limit">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="12">
            <b-card header="Observaciones"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>
                    <b-col lg="12">
                        <b-form-group label="Observaciones">
                            <b-form-textarea id="textarea"
                                            size="sm"
                                            rows="3"
                                            max-rows="6"
                                            v-model="crud.form.observations">
                            </b-form-textarea>
                        </b-form-group>
                    </b-col>                    
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="12">            
            <b-button variant="dark" size="sm" class="pull-right" @click="save()">Guardar</b-button>
        </b-col>
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import FindObject from '@/components/inc/find/findObject'  
    import Funciones from '@/handler/funciones'    

    export default {
        components: {
            FindObject,        
        },
        props: [
            'suppliers',
            'suppliersID',
        ],
        data() {
            return {
                crud: {
                    form: {
                        id: 0,
                        suppliers_id: 0,
                        business_name: '',
                        types_documents: null,
                        types_documents_id: 0,
                        documents_number: '',
                        fiscal_conditions: null,
                        fiscal_conditions_id: 0,
                        address: '',
                        city: '',
                        postal_code: '',
                        state: '',
                        country: '',
                        credit_limit: 0.00,
                        methods_payment: null,
                        methods_payment_id: 0,
                        observations: '',
                        points_sales: null,
                        points_sales_id: 0                        
                    }
                },
                componentKey: 0,
            }
        },        
        watch: {            
            suppliers: function (newQuestion, oldQuestion) {
                this.load()
            }
        },
        methods: {
            load() {                
                if(this.suppliers.data_fiscal){
                    this.crud.form.id = this.suppliers.data_fiscal.id,
                    this.crud.form.suppliers_id = this.suppliers.data_fiscal.suppliers_id,
                    this.crud.form.business_name = this.suppliers.data_fiscal.business_name,                    
                    this.crud.form.documents_number = this.suppliers.data_fiscal.documents_number,                    
                    this.crud.form.address = this.suppliers.data_fiscal.address,
                    this.crud.form.city = this.suppliers.data_fiscal.city,
                    this.crud.form.postal_code = this.suppliers.data_fiscal.postal_code,
                    this.crud.form.state = this.suppliers.data_fiscal.state,
                    this.crud.form.country = this.suppliers.data_fiscal.country,
                    this.crud.form.credit_limit = this.suppliers.data_fiscal.credit_limit,                    
                    this.crud.form.observations = this.suppliers.data_fiscal.observations   
                    
                    this.crud.form.types_documents = this.suppliers.data_fiscal.types_documents,
                    this.crud.form.types_documents_id = this.suppliers.data_fiscal.types_documents_id,
                    
                    this.crud.form.fiscal_conditions = this.suppliers.data_fiscal.fiscal_conditions,
                    this.crud.form.fiscal_conditions_id = this.suppliers.data_fiscal.fiscal_conditions_id,

                    this.crud.form.methods_payment = this.suppliers.data_fiscal.methods_payment,
                    this.crud.form.methods_payment_id = this.suppliers.data_fiscal.methods_payment_id,

                    this.crud.form.points_sales_id = this.suppliers.data_fiscal.points_sales_id
                    this.crud.form.points_sales = this.suppliers.data_fiscal.points_sales                         
                    
                    this.forceRerender()        
                } else {
                    this.crud.form.suppliers_id = this.suppliersID                    
                }                
            },

            save() {
                let loader = this.$loading.show();                
                
                if (this.crud.form.id) {
                    var result = serviceAPI.editarSalesSuppliers(this.crud.form);
                } else {
                    var result = serviceAPI.agregarSalesSuppliers(this.crud.form);
                }

                result.then((response) => {                    
                    loader.hide()                    
                    this.$awn.success("Datos guardados con éxito");
                })
                .catch(error => {
                    loader.hide()
                    this.$awn.alert(Error.showError(error));
                })
            },
             
            loadMethodsPayment (object) {
                if(object){
                    this.crud.form.methods_payment = object
                    this.crud.form.methods_payment_id = object.id             
                } else {
                    this.crud.form.methods_payment = null
                    this.crud.form.methods_payment_id = 0
                }
            },
            loadPointsSales (object) {                    
                if(object){
                    this.crud.form.points_sales = object
                    this.crud.form.points_sales_id = object.id             
                } else {
                    this.crud.form.points_sales = null
                    this.crud.form.points_sales_id = 0
                }
            },              
            loadFiscalConditions (object) {
                if(object){
                    this.crud.form.fiscal_conditions = object
                    this.crud.form.fiscal_conditions_id = object.id             
                } else {
                    this.crud.form.fiscal_conditions = null
                    this.crud.form.fiscal_conditions_id = 0
                }
            },
            loadTypeDocuments (object) {
                if(object){
                    this.crud.form.types_documents = object
                    this.crud.form.types_documents_id = object.id             
                } else {
                    this.crud.form.types_documents = null
                    this.crud.form.types_documents_id = 0
                }
            },             
            forceRerender() {
                this.componentKey += 1;  
            },                        

            validDocumentNumber() {
              this.crud.form.documents_number = this.crud.form.documents_number.replace(/\D/g, '')
              
              if(this.crud.form.types_documents.name == 'CUIT') {
                if(!Funciones.validarCUIT(this.crud.form.documents_number)) {
                  this.$awn.alert("El CUIT es inválido");   
                  this.crud.form.documents_number = ''           
                }            
              }
            }            
        }
    }
</script>
<style scoped>
    .card-header {
        padding: 1px;   
        padding-left: 5px;   
        font-weight: bold;     
    }
</style>